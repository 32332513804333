
import { mapGetters, mapActions } from 'vuex';
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import ExitScanAndGo from '@/views/ScanProduct/ExitScanAndGo/ExitScanAndGo.vue';
import ModalService from '@/modules/modals/services/modal.service';
import WCCustomizeButton from '@/modules/cart/components/WCCustomizeButton/WCCustomizeButton.vue';
import WCCustomizeButtonMobile from '@/modules/cart/components/WCCustomizeButton/WCCustomizeButtonMobile.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WCAddToCartQuantityAdjuster',
  emits: ['decrement', 'addToCart'],
  mixins: [CartControlsMixin, OrderTypesMixin],
  props: {
    linkButtonType: {
      type: Boolean,
      default: false,
    },
    describedBy: {
      type: String,
    },
    showMobileCustomize: {
      type: Boolean,
    },
    priceEmbeddedLineItem: {
      type: Object,
      required: false,
    },
    variation: {},
    isListItem: {
      type: Boolean,
      default: false,
    },
    showOrderTypeDropdown: {
      type: Boolean,
      default: false,
    },
    showStoreSelectModal: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    WCCustomizeButton,
    WCCustomizeButtonMobile,
  },
  computed: {
    ...mapGetters({
      isInStore: 'cart/getInStore',
    }),
    mtoItemNeedsCustomization() {
      return (
        (this.item.hasModifiers || this.item.hasLinkedItems) &&
        !this.variation &&
        !this.priceEmbeddedLineItem
      );
    },
    itemVariation() {
      return this.variation && this.variation.id ? this.variation.id : this.variation;
    },
    showQuantityAdjuster() {
      return !(this.isSoldByRandomWeight(this.item) && this.isCustomerModeScan);
    },
    showRandomWeight() {
      return (
        this.isSoldByRandomWeightWithDualAdjusters(this.item) && !this.mtoItemNeedsCustomization
      );
    },
  },
  methods: {
    ...mapActions({
      setInStore: 'cart/setInStore',
    }),
    async onClickAddToCart(item) {
      await this.setInStore(this.isCustomerModeScan);

      if (this.isCustomerModeScan || (!this.isInStore && !this.isCustomerModeScan)) {
        await this.increment(
          item,
          this.variation,
          this.priceEmbeddedLineItem,
          this.showStoreSelectModal,
        );
        this.$emit('addToCart');

        if (this.isInStore && !this.isCustomerModeScan) {
          try {
            await ModalService.open(ExitScanAndGo);
          } catch (error) {
            console.error(error);
          }
        }
      }
      this.$nextTick(() => {
        this.$refs?.incrementButtonRef?.focus();
      });
    },
    onClickDecrement(item, variation) {
      this.$emit('decrement');
      this.decrement(item, variation);
    },
  },
});
