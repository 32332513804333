<template>
  <main class="home">
    <WCProductRow
      v-if="showOnSaleRecommendations"
      class="my-md-3"
      :rowId="'OSR-9999-9999'"
      :rowName="$t('osrRowTitle')"
      :hideIfEmpty="true"
      data-testid="onSaleRecommendationsRow"
    />
    <WCProductRow
      v-if="showHomepageRecommendations"
      class="my-md-3"
      :rowId="'HPR-9999-9999'"
      :rowName="$t('hprRowTitle')"
      :hideIfEmpty="true"
      data-testid="homepageRecommendationsRow"
    />
    <WCCouponRow
      v-if="showCouponRecommendations"
      class="my-md-3"
      :useRecs="true"
      @noRecs="this.overrideCouponRecommendations = true"
      data-testid="homepageCouponRecommendationsRow"
    />
    <WCOrderTypes v-if="$configuration.orderTypesEnabled" :orderTypes="visibleOrderTypes" />

    <WCHomePage
      :name="$configuration.store.name"
      :rows="$configuration.rows"
      :hideCoupons="showCouponRecommendations"
    />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FocusMixin from '@/mixins/FocusMixin';
import WCOrderTypes from '@/modules/orderTypes/components/WCOrderTypes/WCOrderTypes.vue';
import WCHomePage from '@/views/Home/components/WCHomePage/WCHomePage.vue';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCProductRow from '@/components/WCProductRow/WCProductRow.vue';
import WCCouponRow from '@/components/WCCouponRow/WCCouponRow.vue';

export default {
  components: { WCHomePage, WCOrderTypes, WCProductRow, WCCouponRow },
  name: 'Home',
  data() {
    return {
      overrideCouponRecommendations: false,
    };
  },
  mounted() {
    this.setWebAds(this.$configuration.ads);
  },
  unmounted() {
    this.setWebAds([]);
  },
  computed: {
    ...mapGetters({ isGuest: 'user/isGuest' }),
    showHomepageRecommendations() {
      return (
        this.$configuration.homepageRecommendationsEnabled &&
        !this.$configuration.orderTypesEnabled &&
        !this.isGuest
      );
    },
    showOnSaleRecommendations() {
      return (
        this.$configuration.onSaleRecommendationsEnabled &&
        !this.$configuration.orderTypesEnabled &&
        !this.isGuest
      );
    },
    showCouponRecommendations() {
      return (
        this.$configuration.couponRecommendationsEnabled &&
        this.$configuration.allowCouponClipping &&
        !this.overrideCouponRecommendations &&
        !this.$configuration.orderTypesEnabled &&
        !this.isGuest
      );
    },
  },
  methods: {
    ...mapActions({ setWebAds: 'browse/setWebAds' }),
  },
  mixins: [FocusMixin, OrderTypesMixin],
};
</script>
